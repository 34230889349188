<template>
    <div class="login">
        <!-- 登录模态框 -->
        <el-dialog :visible.sync="dialogVisible" width="700px"  :show-close="false">
            <div class="loginContent">
                <p class="title">心理测评系统</p>
                <div class="contentBox">
                    <div class="topTitle">
                        <div @click="activeName = 'first'" class="sideBox">
                            <!-- :class="activeName === 'first' ? 'bottomBorder' : ''" -->
                            <div>
                                <span>登录</span>
                                <span v-if="activeName === 'first'" class="bottomBorder"></span>
                            </div>
                        </div>
                        <div class="sideBox" @click="activeName = 'second'">
                            <div>
                                <span>注册</span>
                                <span v-if="activeName === 'second'" class="bottomBorder"></span>
                            </div>
                        </div>
                    </div>
                    <div class="contentLogin" v-if="activeName === 'first'">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                            <el-form-item prop="username">
                                <el-input v-show="loginType === 'true'" prefix-icon="el-icon-user" placeholder="登录名/手机号"
                                    v-model="ruleForm.username"></el-input>
                                <el-dropdown @command="handleCommand"
                                    style="position:absolute; right:5px; font-size:28px; line-height:45px; color: #DCDFE6; font-weight: 600;">
                                    <span class="el-dropdown-link">
                                        <i slot="prefix" class="el-icon-arrow-down" style="color: #C0C4CC"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command='true'>登录名</el-dropdown-item>
                                        <el-dropdown-item command='false'>手机号</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <el-input v-show="loginType === 'false'" v-model="ruleForm.username" placeholder="手机号"
                                    prefix-icon="el-icon-user" type="number" />
                                <el-dropdown @command="handleCommand"
                                    style="position:absolute;right:5px; font-size:28px; line-height:45px; color: #DCDFE6; font-weight: 600;">
                                    <span class="el-dropdown-link">
                                        <i slot="prefix" class="el-icon-arrow-down" style="color: #C0C4CC"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command='true'>密码登录</el-dropdown-item>
                                        <el-dropdown-item command='false'>手机验证码登录</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input v-show="loginType === 'true'" v-model="ruleForm.password"
                                    prefix-icon="el-icon-lock" placeholder="密码" show-password></el-input>
                                <div class="codeContent" v-show="loginType === 'false'">
                                    <el-input v-model="ruleForm.password" prefix-icon="el-icon-key" placeholder="验证码"
                                        show-telephone></el-input>
                                    <div class="code"
                                        style="position:absolute;right:10px;top: 0px; line-height: 45px; cursor: pointer;">
                                        <span v-if="!loginCode" class="noCode" @click="getCodeLogin">获取验证码</span>
                                        <span v-else class="hasCode">{{ loginCodeTime }}s 后重试</span>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <span class="first loginBtn" @click="submitForm('ruleForm')">登录</span>
                                <!-- <el-button type="primary" class="first" @click="submitForm('ruleForm')">登录
                                                    </el-button> -->
                            </el-form-item>
                            <el-form-item>
                                <span class="loginBtn" @click="loginAuth">游客访问</span>
                                <!-- <el-button type="primary" plain @click="loginAuth">游客访问</el-button> -->
                            </el-form-item>
                        </el-form>
                        <!-- <div class="forgetPwd">
                            <span>忘记密码</span>
                        </div> -->


                    </div>
                    <!-- 注册区域 -->
                    <div class="contentRegister" v-else v-loading="loading">
                        <div class="registerHeight" v-show="!registerStatus">
                            <el-form :model="RegisterForm" :rules="registerRules" ref="ruleFormRegister"
                                class="demo-ruleForm">
                                <div class="registerStyle">
                                    <el-form-item prop="nickname">

                                        <el-input v-model="RegisterForm.nickname" prefix-icon="el-icon-user"
                                            placeholder="* 姓名"></el-input>
                                    </el-form-item>
                                    <el-form-item prop="username">
                                        <el-input v-model="RegisterForm.username" prefix-icon="el-icon-user"
                                            placeholder="* 登录名"></el-input>
                                    </el-form-item>
                                    <el-form-item prop="telephone">
                                        <el-input v-model="RegisterForm.telephone" prefix-icon="el-icon-mobile-phone"
                                            placeholder="* 手机号" type="number" show-telephone>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item prop="code">
                                        <div class="codeContent">
                                            <el-input v-model="RegisterForm.code" prefix-icon="el-icon-key"
                                                placeholder="* 验证码" show-telephone></el-input>
                                            <div class="code">
                                                <span v-if="!codeStatus" class="noCode" @click="getCode">获取验证码</span>
                                                <span v-else class="hasCode">{{ totalTime }}s 后重试</span>
                                            </div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item prop="password">
                                        <el-input v-model="RegisterForm.password" prefix-icon="el-icon-lock"
                                            placeholder="* 密码" show-password></el-input>
                                    </el-form-item>
                                    <el-form-item prop="checkPass">
                                        <el-input v-model="RegisterForm.checkPass" prefix-icon="el-icon-lock"
                                            placeholder="* 确认密码" show-password>
                                        </el-input>
                                    </el-form-item>
                                </div>
                                <el-form-item prop="name">
                                    <span class="first loginBtn" @click="submit('ruleFormRegister')">确认</span>
                                    <!-- <el-button type="primary" class="first" @click="submit('ruleFormRegister')">
                                        确认
                                    </el-button> -->
                                </el-form-item>
                            </el-form>
                        </div>
                        <div v-show="registerStatus" class="successRegister">
                            <div class="img">
                                <img src="@/assets/images/service/gauge/success.png" alt="">
                                <span class="font">注册成功</span>
                                <!-- <el-button type="primary" @click="toLogin">
                                    返回登录
                                </el-button> -->
                                <span @click="toLogin" class="first loginBtn">返回登录</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

         
        </el-dialog>
</div>
</template>
<script>

import { login, touristLogin, getLoginCode, loginTel, getCodes, register } from '@/api/gauge'
import axios from 'axios';
import request from '@/utils/request'
export default {
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.RegisterForm.checkPass !== '') {
                    this.$refs.ruleFormRegister.validateField('checkPass');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.RegisterForm.password) {
                callback(new Error('密码输入不一致，请检查并重新输入!'));
            } else {
                callback();
            }
        };
        return {
            dialogVisible: false,
            activeName: 'first', //当前激活
            loginCodeTime: 60, // 登录验证码时长
            registerStatus: false, // 判断用户是否注册成功
            codeStatus: false, // 判断用户是否点击获取验证码
            totalTime: 60, // 验证的的时长
            loading: false, // 加载动画
            loginCode: false, // 判断用户是否点击获取登录验证码
            loginType: 'true', // true.为登录名登录， false为手机号登录
            ruleForm: { //登录信息
                username: null,
                password: null
            },
            RegisterForm: {
                nickname: null, // 姓名
                username: null, // 登录名
                telephone: null, // 电话
                password: null, // 密码
                code: null, // 验证码
                departmentId: null // 注册用户所属组织机构
                // occupation: null, //职业
                // education: null, // 学历
                // grade: null, // 年级
                // checkPass: null // 确认密码
            },
            rules: {
                username: [
                    { required: true, message: '请输入登录名/手机号！', trigger: 'blur' }
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码/验证码！', trigger: 'blur' }
                ]
            },
            registerRules: {
                password: [
                    { validator: validatePass, trigger: 'blur' },
                    { required: true, message: '请输入密码！', trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'change' },
                    { required: true, message: '请再次输入密码！', trigger: 'blur' }
                ],
                nickname: [
                    { required: true, message: '请输入姓名！', trigger: 'blur' },
                    { max: 15, message: '长度在15个字符以内', trigger: 'change' }
                ],
                username: [
                    { required: true, message: '请输入登录名！', trigger: 'blur' }
                ],
                telephone: [
                    { required: true, message: '请输入手机号！', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'change' }
                ],
                code: [
                    { required: true, message: '请输入验证码！', trigger: 'blur' }
                ],
            },

        };
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        // 打开当前模态框
        openLogin() {
            this.dialogVisible = true;
        },
        // 登录
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // console.log(this.ruleForm);
                    // 用户名登录
                    if (this.loginType === 'true') {
                        const res = await login(JSON.stringify(this.ruleForm));
                        console.log(res);
                        if (res.code === 200) {
                            sessionStorage.setItem('token', res.data.token);
                            sessionStorage.setItem('userInfo', JSON.stringify(res.data));
                            sessionStorage.setItem('userType', 'user')
                            this.$message({
                                showClose: true,
                                message: '登录成功！',
                                type: 'success'
                            });
                            setTimeout(() => {
                                this.dialogVisible = false;
                            }, 1000);
                        } else {
                            this.$message({
                                showClose: true,
                                message: '登录失败！' + res.msg,
                                type: 'error'
                            });
                        }
                    } else {
                        // sessionStorage.setItem('token', null);
                        let info = new FormData();
						info.append("telephone", this.ruleForm.username);
						info.append("code", this.ruleForm.password);
                        // let info = {
                        //     telephone: this.ruleForm.username,
                        //     code: this.ruleForm.password
                        // }
						const {data} = await axios({
                            method: 'post',
                            url: `/http/auth/telephone/login`,
                            data: info
                        });
                       
                        console.log(data);
                        if (data.code === 200) {
                            sessionStorage.setItem('token', data.data.token);
                            sessionStorage.setItem('userInfo', JSON.stringify(data.data));
                            sessionStorage.setItem('userType', 'user')

                            this.$message({
                                showClose: true,
                                message: '登录成功！',
                                type: 'success'
                            });
                            setTimeout(() => {
                                this.dialogVisible = false;
                            }, 1000);
                        } else {
                            this.$message({
                                showClose: true,
                                message: '登录失败！' + data.msg,
                                type: 'error'
                            });
                        }
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 游客登录
        async loginAuth() {
            const res = await touristLogin();
            console.log(res)
            if (res.code === 200) {
                sessionStorage.setItem('token', res.data.token);
                sessionStorage.setItem('userInfo', JSON.stringify(res.data));
                sessionStorage.setItem('userType', 'user')
                this.$message({
                    showClose: true,
                    message: '登录成功！',
                    type: 'success'
                });

                // localStorage.setItem('token', res.data.token)
                setTimeout(() => {
                    this.dialogVisible = false;
                }, 1000);
            }
        },
        // 注册
		submit(formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					this.loading = true;
					let info = JSON.parse(JSON.stringify(this.RegisterForm));
					// delete info.code;
					delete info.checkPass;
					console.log(info);
					// info.source = 2;
					// alert('submit!');
					setTimeout(() => {
						this.loading = false;
					}, 500);
					// if (this.planId === 'undefine') {
					// 	this.planId = null;
					// }
					info = { ...info, planId: 1 }
					console.log(info);
					const res = await register(JSON.stringify(info));
					console.log(res);
					if (res.code === 200) {
						// this.$message({
						// 	showClose: true,
						// 	message: '注册成功',
						// 	type: 'success'
						// });

						this.RegisterForm = {
							nickname: null, // 姓名
							username: null, // 登录名
							telephone: null, // 电话
							password: null, // 密码
							code: null, // 验证码
							checkPass: null // 确认密码
						}
						this.codeStatus = false;
						this.totalTime = 60;
						this.registerStatus = true;
					} else {
						this.$message({
							showClose: true,
							message: '注册失败！' + res.msg,
							type: 'error'
						});
					}
				} else {
					console.log('error submit!!');
					this.$message({
						showClose: true,
						message: '请将信息填写完整！'
					});
					return false;
				}
			});
		},
        // 获取登录验证码
        async getCodeLogin() {
            // console.log(this.RegisterForm.telephone);
            if (this.ruleForm.username !== null && this.ruleForm.username !== '') {
                this.loginCode = true;
                let clock = window.setInterval(() => {
                    this.loginCodeTime--;
                    if (this.loginCodeTime < 0) {
                        //当倒计时小于0时清除定时器
                        window.clearInterval(clock); //关
                        this.loginCodeTime = 60;
                        this.loginCode = false;
                    }
                }, 1000);
                const res = await getLoginCode({ telephone: this.ruleForm.username });
                console.log(res);
                // if (res.code === 200) {

                // }

            } else {
                this.$message({
                    showClose: true,
                    message: '请输入手机号！'
                });
            }
        },
        // 获取注册验证码
        async getCode() {
            // console.log(this.RegisterForm.telephone);

            if (this.RegisterForm.telephone !== null && this.RegisterForm.telephone !== '') {
                this.codeStatus = true;
                let clock = window.setInterval(() => {
                    this.totalTime--;
                    if (this.totalTime < 0) {
                        //当倒计时小于0时清除定时器
                        window.clearInterval(clock); //关
                        this.totalTime = 60;
                        this.codeStatus = false;
                    }
                }, 1000);
                const res = await getCodes({ telephone: this.RegisterForm.telephone });
                console.log(res);
                if (res.code !== 200) {
                    this.$message({
                    showClose: true,
                    message: res.msg
                });
                }

            } else {
                this.$message({
                    showClose: true,
                    message: '请输入手机号！'
                });
            }
        },
        // 返回登录
        toLogin() {
            this.activeName = 'first';
            this.registerStatus = false;

        },
        // 切换登录方式
        handleCommand(val) {
            console.log(val)
            this.ruleForm.username = null;
            this.ruleForm.password = null;
            this.loginType = val;
            console.log(this.loginType)
        },
    }
};
</script>

<style lang="scss" scoped>
.login {
    ::v-deep .el-dialog__header {
        padding: 0px !important;
    }

    ::v-deep .el-dialog__body {
        padding: 0px !important;
    }

    ::v-deep input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .loginContent {
        padding: 50px 55px;

        .title {
            font-size: 32px;
            font-weight: 600;
            color: var(--login-color);
            line-height: 45px;
            text-align: center;
            margin-bottom: 20px;
        }

        .contentBox {
            ::v-deep .el-input__inner {
                height: 45px !important;
                line-height: 45px !important;
            }

            .topTitle {
                display: flex;
                margin-bottom: 30px;

                .sideBox {
                    margin-right: 50px;
                }

                // p,
                // span {
                //     display: inline-block;
                // }
                // 
                span {
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--login-color);
                    line-height: 40px;

                    cursor: pointer;
                }

                .bottomBorder {

                    display: block;
                    height: 8px;
                    width: 100%;
                    background: var(--login-color);
                    border-radius: 3px;
                    // border-bottom: 8px solid var(--login-color);
                }
            }
            .loginBtn {
                    cursor: pointer;
                    box-sizing: border-box;
                    text-align: center;
                    display: block;
                    width: 100%;
                    height: 45px;
                    line-height: 45px;
                    color: var(--login-color);
                    font-size: 16px;
                    font-weight: 600;
                    border-radius: 4px;
                    border: 1px solid var(--login-color);
                    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.15);

                }
                .first {
                    color: #FFFFFF;
                    margin-top: 20px;
                    background: var(--login-color);

                }
            .contentLogin {
                margin-top: 5px;
                box-sizing: border-box;



                .forgetPwd {
                    // display: inline-block;
                    margin-bottom: 20px;
                    text-align: right;
                    font-size: 12px;
                    font-weight: 400;
                    color: var(--login-color);
                    cursor: pointer;
                }

                

                
            }

            .contentRegister {
                .el-button {
                    margin-top: 20px;

                }

                .codeContent {
                    position: relative;

                    .code {
                        position: absolute;
                        top: 0;
                        right: 0px;
                        display: flex;
                        height: 100%;
                        align-items: center;
                        // height: 100%;
                        // width: 100px;
                        // border-left: 1px solid #E6E6E6;
                        // text-align: center;
                        // line-height: 45px;

                        span {
                            display: inline-block;
                            line-height: 26px;
                            width: 90px;
                            height: 26px;
                            border-left: 1px solid #E6E6E6;
                            font-size: 12px;
                            font-weight: 400;
                            // color: var(--custom-color);
                            padding-left: 14px;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }

                .successRegister {
                    padding-top: 50px;

                    .img {
                        text-align: center;

                        img {
                            height: 100px;
                            width: 100px;
                        }

                        .font {
                            display: block;
                            margin-top: 10px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #303030;
                            margin-bottom: 66px;
                        }
                    }

                }
            }
        }
    }

}
@media screen and (max-width: 600PX) {
    ::v-deep .el-dialog{
        width: 100% !important;
    }
}
</style>