<template>
    <div class="introduce">
        <!-- 量表介绍 -->
        <div class="introduceContent">
            <p class="title p2">介绍语</p>
            <div>
                <span class="span2">{{ gaugeInfo.memo }}</span>
            </div>
        </div>
        <div class="EvaluationBtn" @click="startEvaluation">
            <span class="span2">开始答题</span>
        </div>
        <LoginVue ref="login"></LoginVue>
</div>
</template>

<script>

import LoginVue from './Login.vue'
import { getGaugeDetail, touristLogin } from '@/api/gauge'
// import { touristLogin } from '@/api/gauge'
export default {
    components: { LoginVue },
    data() {
        return {
            gaugeInfo: {},// 量表详情
        }
    },
    methods: {
        // 开始测评
        startEvaluation() {
            // 需要先判断用户是否登录账号
            console.log(sessionStorage.getItem('userType'))
            if (sessionStorage.getItem('userType') === 'tourist') {
                // console.log('游客');
                this.$refs.login.openLogin();
            } else {
                // console.log('已登录')
                // this.$refs.login.openLogin();
                this.$router.push({
                    path: '/test/evaluation/answer',
                    query: {
                        gaugeId: this.$route.query.gaugeId,
                        isRandom: this.$route.query.isRandom,
                        type: this.$route.query.type
                    }
                })
            }
            // this.$router.push({
            //     path: '/service/evaluation/answer',
            //     query: {
            //         gaugeId: this.$route.query.gaugeId,
            //         isRandom: this.$route.query.isRandom,
            //         type: this.$route.query.type
            //     }
            // })
        },
        // 获取量表详情
        async gaugeDetail() {
            const res = await getGaugeDetail(this.$route.query.gaugeId);
            console.log(res);
            if (res.code === 200) {
                this.gaugeInfo = res.data;
            }
        },
        async tourist() {
      const res = await touristLogin();
      // console.log(res);
      if (res.code === 200) {
        sessionStorage.setItem('userType', 'tourist')
        sessionStorage.setItem('token', res.data.token);
        sessionStorage.setItem('userInfo', JSON.stringify(res.data));
        location.reload();
        // this.GaugeList();
      }
    },
    },
    created() {
        if (!sessionStorage.getItem('token')) {
            this.tourist();
        } 
        this.gaugeDetail();
        // console.log(this.$store.state.gaugeListInfo)
    }
}
</script>

<style lang="scss" scoped>
.introduce {
    padding-bottom: 400px;

    .introduceContent {
        p {
            width: 100%;
            height: 35px;
            background: #EEEEEE;
            font-weight: 600;
            color: var(--titleFont-color);
            line-height: 35px;
            padding: 0px 10px;
            box-sizing: border-box;
            margin-bottom: 10px;
        }

        span {
            color: var(--memoFont-color);
            line-height: 22px;
        }
    }

    .EvaluationBtn {
        margin-top: 60px;
        width: 100%;
        height: 40px;
        background: var(--custom-color);
        text-align: center;
        cursor: pointer;

        span {
            font-weight: 600;
            color: var(--headFont-color);
            line-height: 40px;
        }
    }
}

</style>